import React from "react";

import { color } from "../../design/theme";

import {
    LandingTimelineCardContainer, LandingTimelineCardTitle, LandingTimelineCardPin,
    LandingTimelineCardDesc, LandingTimelineCardContent, LandingTimelineCardStatus,
    LandingTimelineCardExpo, LandingTimelineCardQuote, LandingTimelineCardQuoteIcon
} from "./landingTimelineCard.styles";

import chShield from "../../assets/images/admin-ch.svg";

const LandingTimelineCard = ({ title, desc, children, icon, quote, admin, current }) => {

    return (
        <LandingTimelineCardContainer>
            <LandingTimelineCardPin>
                {
                    admin ? (
                        <img src={chShield} alt={"Swiss Emblem"} />
                    ) : null
                }
            </LandingTimelineCardPin>
            <LandingTimelineCardStatus
                name={icon}
                color={color.blue}
                size={40}
                current={current}
            />
            <LandingTimelineCardContent>
                <LandingTimelineCardTitle>{title}</LandingTimelineCardTitle>
                <LandingTimelineCardDesc>{desc}</LandingTimelineCardDesc>
                {
                    children ? (
                        <LandingTimelineCardExpo>
                            { children }
                        </LandingTimelineCardExpo>
                    ) : null
                }
                {
                    quote ? (
                        <LandingTimelineCardQuote>
                            <LandingTimelineCardQuoteIcon
                                name={"quote"}
                                color={color.blue}
                                size={40}
                            />
                            <span>{quote}</span>
                        </LandingTimelineCardQuote>
                    ) : null
                }
            </LandingTimelineCardContent>
        </LandingTimelineCardContainer>
    )
}

export default LandingTimelineCard;
import styled from "styled-components";
import { font, color } from "../../design/theme";

export const MainCardCTAWrapper = styled.div`
    width: 100vw;
    height: max-content;
    justify-content: center;
    display: flex;
    background-color: ${color.white};
    padding-bottom: 100px;
`

export const MainCardCTAContainer = styled.div`
    width: 1000px;
    max-width: 90%;
    height: 300px;
    background-color: ${color.black};
    border-radius: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
`

export const MainCardCTABackground = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    
    & > img {
        height: inherit;
        width: inherit;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
    }
    
`

export const MainCardCTAContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 25px;

    & ::selection {
        background: ${color.blue};
    }
`

export const MainCardCTATitle = styled.h2`
    font-size: 28px;
    font-family: ${font.pulpDisplayBold}; 
    color: ${color.white};
    align-items: center;
    display: flex;
`

export const MainCardCTASquareLogo = styled.img`
    height: 40px;
    width: auto;
    display: inline-flex;
    flex-shrink: 0;
    margin-right: 15px;
`

export const MainCardCTADesc = styled.p`
    font-size: 20px;  
    text-align: left;
    font-family: ${font.pulpDisplayMedium}; 
    color: ${color.gray4};
`
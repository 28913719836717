import React, { useState, Fragment } from "react";
import { Form, Field } from "react-final-form";

import { isValidEmail } from "../utils";

import Dropdown from "../dropdown/dropdown.component";
import InputField from "../inputField/inputField.component";
import ModalAction from "../modalAction/modalAction.component";

const ModalCapture = ({ onDismiss, callback }) => {
    const [countryActive, setCountryActive] = useState({ code: "ch", name: "Switzerland" });
    const [loadingActive, setLoadingActive] = useState(false);

    const required = (value) => (value ? undefined : "required")
    const mustBeEmail = (value) => (
        (!isValidEmail(value) || (value.length === 0)) ?
            "typeError" : undefined
    )

    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)

    const onSubmit = async (values) => {
        console.log("values", values)
        setLoadingActive(true);
        setTimeout(() => {
            callback(true)
            setLoadingActive(false);
        }, 600)

        /*if (param === "signup") {
            await signUpEmailStart(values);
        } else if (param === "login") {
            await signInEmailStart(values);
        }*/
    }

    return (
        <Fragment>
            <Dropdown
                contentData={{
                    [countryActive.code]: {
                        type: countryActive.code,
                        desc: { en: countryActive.name },
                        icon: "140002"
                    }
                }}
                activeType={countryActive.code}
                callback={(type) => setCountryActive(type)}
                lang={"en"}
            />
            <Form
                onSubmit={onSubmit}
                subscription={{ submitting: true, invalid: true, pristine: true }}
                mutators={{
                    toggleScreenshotCheck: (args, state, utils) => {
                        utils.changeValue(state, "attachScreenshot", () => {
                            const { attachScreenshot } = state.formState.values;
                            return !attachScreenshot;
                        })
                    },
                    toggleTraceLogCheck: (args, state, utils) => {
                        utils.changeValue(state, "attachTraceLog", () => {
                            const { attachTraceLog } = state.formState.values;
                            return !attachTraceLog;
                        })
                    }
                }}
                keepDirtyOnReinitialize={false}
                render={({ handleSubmit, form, pristine, invalid , submitting}) => (
                    <form onSubmit={handleSubmit} name={"leads"} method={"POST"} data-netlify={"true"} data-netlify-honeypot={"bot-field"}>
                        <input type={"hidden"} name={"form-name"} value={"leads"} />
                        <Field
                            validate={composeValidators(required, mustBeEmail)}
                            subscription={{ touched: true, dirty: true, error: true, value: true, valid: true, initial: true, pristine: true }}
                            name={"email"}
                            component={InputField}
                            placeholder={"hey@sample.mail"}
                            type={"email"}
                            checkType={true}
                            required={"true"}
                            modal
                        />
                        {/*<Field
                            name={"attachScreenshot"}
                            type={"checkbox"}
                            initialValue={true}
                            render={({ input }) => (
                                <Fragment />
                            )}
                        />*/}
                        <input
                            type={"hidden"}
                            value={countryActive.code}
                            name={"country"}
                        />
                        <ModalAction
                            type={"submit"}
                            actionDesc={"Let's go!"}
                            disabled={invalid || pristine || submitting}
                            loading={loadingActive}
                            onDismiss={onDismiss}
                        />
                    </form>
                )}
            />
        </Fragment>
    )
}

export default ModalCapture;
import React, { useEffect, useState } from "react";

import { color } from "../../design/theme";

import { MainMenuBar, MainMenuLogo, MainMenuNavPoint, MainMenuContainer } from "./navigation.styles";
import reavideLogo from "../../assets/images/reavide-logo.svg";

import CustomButton from "../customButton/customButton.component";
import ScrollProgress from "../scrollProgress/scrollProgress.component";
import Modal from "../modal/modal.component";
import ModalAction from "../modalAction/modalAction.component";

const Navigation = ({ callback }) => {
    const[hasMoved, setHasMoved] = useState(false);
    const[openAlpha, setOpenAlpha] = useState(false);

    const activateModal = (event) => {
        event?.stopPropagation();
        setOpenAlpha(true);
    }

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (typeof window !== undefined && window.requestIdleCallback) {
                window.requestIdleCallback(
                    () => setHasMoved(entries[0].intersectionRatio < 1),
                    { timeout: 200 }
                )
            } else setHasMoved(entries[0].intersectionRatio < 1)
        }, { root: document.querySelector("body"), threshold: 1, rootMargin: "50px 0px 0px 0px" });

        const target = document.getElementById("#hero")
        if(observer) observer?.observe(target);

        return () => observer.unobserve(target);
    }, [])

    return (
        <MainMenuBar>
            <MainMenuContainer sticky={hasMoved}>
                <MainMenuLogo src={reavideLogo} sticky={hasMoved} />
                <MainMenuNavPoint
                    onClick={() => document.getElementById("#info").scrollIntoView({ behavior: "smooth" })}
                    sticky={hasMoved}
                >{"Info"}</MainMenuNavPoint>

                <MainMenuNavPoint
                    onClick={(event) => activateModal(event)}
                    sticky={hasMoved}
                >{"✪ Alpha"}</MainMenuNavPoint>
                {
                    openAlpha ? (
                        <Modal
                            type={"prompt"}
                            title={"reavide News Alpha"}
                            desc={"Access to the reavide News web alpha is currently restricted to our publisher partners and us (of course 🧐).\n\nPlease understand that we cannot provide special access. However we kindly invite you to join our mailing list to get notified when we launch!"}
                            onDismiss={() => setOpenAlpha(!openAlpha)}
                            icon={"warning"}
                            refType={"warning"}
                        >
                            <ModalAction
                                callback={() => window.open("https://reavi.de/news", "_blank")}
                                onDismiss={() => setOpenAlpha(!openAlpha)}
                                actionDesc={"Understood & Go"}
                                actionColor={color.yellow}
                                textColor={color.black}
                            />
                        </Modal>
                    ) : null
                }

                <CustomButton
                    callback={callback}
                    width={140}
                    desc={"Sign Up Now"}
                    color={color.black}
                    size={"small"}
                    solid
                />

                {
                    hasMoved ? (
                        <ScrollProgress target={document.getElementById("gatsby-focus-wrapper")} />
                    ) : null
                }
            </MainMenuContainer>
        </MainMenuBar>
    )
}

export default Navigation
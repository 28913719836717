import React, { Fragment } from "react";

import { color } from "../../design/theme";

import {
    LandingFeaturesTitle, LandingFeaturesList, LandingFeaturesListItem,
    LandingFeaturesDesc
} from "./landingFeatures.styles";
import Icon from "../../assets/icons/icon.component";

const listData = [
    { desc: "Up to date stories from all your favorite newspapers." },
    { desc: "All premium articles. No more paywalls!" },
    { desc: "Breaking news. Never miss what's important." },
    { desc: "No ads. None. Ever. We promise." },
    { desc: "Read along smart topics to find what actually matters to you most, faster than ever before." },
    { desc: "Get insights to understand your reading behavior." },
]

const LandingFeatures = ({ }) => {
    return (
        <Fragment>
            <LandingFeaturesTitle>
                <span>{"Amazing features 🔥"}</span>
            </LandingFeaturesTitle>
            <LandingFeaturesDesc>
                <span>{"What do you actually get by using reavide News? A whole lot. Here's a small selection of what's to come, just to give you a taste:"}</span>
            </LandingFeaturesDesc>
            <LandingFeaturesList>
                {
                    listData.map(({ desc }) => (
                        <LandingFeaturesListItem key={desc}>
                            <Icon
                                name={"check"}
                                color={color.blue}
                                size={40}
                            />
                            <span>{desc}</span>
                        </LandingFeaturesListItem>
                    ))
                }
            </LandingFeaturesList>
            <LandingFeaturesDesc>
                <span>{"...and much more we can't wait to unveil to you soon! ⏳"}</span>
            </LandingFeaturesDesc>
        </Fragment>
    )
}

export default LandingFeatures;
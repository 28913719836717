import { color } from "../design/theme"

export const capitalize = (string = "", matchAll) => {
    if(matchAll) return string.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(" ")
    else return (string.charAt(0).toUpperCase() + string.slice(1))
}

export const textColor = (darkmode) => {
    return darkmode ? color.white : color.black
}

export const isValidEmail = (string) => {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/.test(string)
}

const CC_REGEX = /^[a-z]{2}$/i;
const OFFSET = 127397;
export const countryCodeEmoji = (cc) => {
    if (!CC_REGEX.test(cc)) {
        const type = typeof cc;
        throw new TypeError(
            `cc argument must be an ISO 3166-1 alpha-2 string, but got '${
                type === 'string' ? cc : type
            }' instead.`,
        );
    }

    const codePoints = [...cc.toUpperCase()].map(c => c.codePointAt() + OFFSET);
    return String.fromCodePoint(...codePoints);
}
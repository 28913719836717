import React, { useState, useRef, useEffect } from "react";

import {
    InputFieldContainer,
    InputFieldInput,
    InputFieldIconContainer,
    InputFieldArea,
    InputAreaInfoContainer,
    InputAreaVisualProgress,
    InputAreaNumericProgress
} from "./inputField.styles";
import Progress from "../progress/progress.component";
import { color } from "../../design/theme";

const InputField = ({ input, meta, placeholder, darkmode, type, checkType, modal, area, max,
                        hidden, insertedValue, callback, reset, required, maxValue, minValue }) => {

    const [value, setValue] = useState(insertedValue || meta.initial || "");
    const ref = useRef(null);
    const inputRef = useRef(null);

    const handleFocusSetting = (event) => {
        event.stopPropagation();
        inputRef.current && inputRef.current.focus();
        let tempVal = inputRef.current.value;
        inputRef.current.value = "";
        inputRef.current.value = tempVal;
    }

    useEffect(() => {
        if(reset && inputRef.current) inputRef.current.focus()
    }, [reset])
    useEffect(() => {
        if(ref.current && area) ref.current.dataset.replicatedValue = (insertedValue ?? "");
    }, [ref, insertedValue])

    const error = area ? !!(meta.error && meta.dirty) : !!(meta.error && meta.touched);
    let unverified = area ? value.length >= 1 ? !meta.valid : "true" : !!((meta.error) && !meta.valid);
    // let unverified = ((value.length === 0) || ((meta.error) ? meta.error.indexOf("typeError") > -1 : false));
    // let unverified = !!(((value.length === 0) || meta.error) && !meta.valid);

    return (
        <InputFieldContainer
            onClick={(event) => handleFocusSetting(event)}
            darkmode={darkmode}
            error={error}
            verified={!unverified}
            value={value}
            type={type}
            hidden={hidden}
            checkType={checkType}
            modal={modal}
            area={area}
            ref={ref}
        >
            {
                ((checkType && !unverified) || error) ? (
                    <InputFieldIconContainer
                        name={(error || unverified) ? "error" : "check"}
                        size={34}
                        color={error ? color.red : color.blue}
                        area={area}
                    />
                ) : null
            }
            {
                area ? (
                    <InputFieldArea
                        onInput={(event) => {
                            setValue(event.target.value)
                            ref.current.dataset.replicatedValue = event.target.value;
                        }}
                        onClick={(event) => event.stopPropagation()}
                        darkmode={darkmode}
                        placeholder={placeholder}
                        rows={"1"}
                        aria-label={placeholder}
                        value={value}
                        type={type}
                        { ...input }
                        autoComplete={"off"}
                        autoCorrect={"off"}
                        tabIndex={"0"}
                        modal={modal}
                        required={!!required}
                        ref={inputRef}
                    />
                ) : (
                    <InputFieldInput
                        onInput={(event) => {
                            setValue(event.target.value)
                            if(callback) callback(event.target.value)
                        }}
                        onClick={(event) => event.stopPropagation()}
                        darkmode={darkmode}
                        placeholder={placeholder}
                        aria-label={placeholder}
                        maxLength={max}
                        max={maxValue}
                        min={minValue}
                        type={type}
                        value={value}
                        { ...input }
                        autoCapitalize={"none"}
                        autoComplete={"off"}
                        autoCorrect={"off"}
                        tabIndex={"0"}
                        modal={modal}
                        ref={inputRef}
                        required
                    />
                )
            }
            {
                area ? (
                    <InputAreaInfoContainer>
                        {
                            value.length ? (
                                <InputAreaVisualProgress>
                                    <Progress
                                        type={"circular"}
                                        value={Number(Math.round(parseFloat((value.length / max) + 'e' + 3)) + 'e-' + 3)}
                                    />
                                </InputAreaVisualProgress>
                            ) : null
                        }
                        <InputAreaNumericProgress
                            length={value.length}
                            max={max}
                        >
                            <span>{value.length <= max ? value.length : `-${value.length - max}`}</span>
                            <span>{` /${max}`}</span>
                        </InputAreaNumericProgress>
                    </InputAreaInfoContainer>
                ) : null
            }
        </InputFieldContainer>
    )
}

export default InputField;
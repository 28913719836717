import React from "react";

import { color } from "../../design/theme";

import { ModalActionButtonContainer } from "./modalAction.styles";
import CustomButton from "../customButton/customButton.component";

const ModalAction = ({ darkmode, onDismiss, lang, callback, loading, actionDesc, actionColor,
                         secondaryCallback, disabled, type, singular, icon, textColor }) => {
    return(
        <ModalActionButtonContainer singular={singular}>
            {
                !singular ? (
                    <CustomButton
                        callback={() => {
                            secondaryCallback?.()
                            onDismiss()
                        }}
                        desc={"Cancel"}
                        color={darkmode ? color.gray4 : color.gray3}
                    />
                ) : null
            }
            <CustomButton
                callback={() => callback?.()}
                desc={actionDesc ?? "Confirm"}
                contentColor={textColor}
                color={actionColor ?? color.blue}
                disabled={disabled}
                width={singular ? 250 : null}
                loading={loading}
                type={type}
                icon={icon ?? "arrow"}
                solid
            />
        </ModalActionButtonContainer>
    )
}

export default ModalAction;
import styled from "styled-components";
import { color } from "../../design/theme";

export const LandingTimelineWrapper = styled.div`
    width: inherit;
    height: max-content;
    position: relative;
    
    &::before {
        content: "";
        position: absolute;
        height: calc(100% - 140px);
        width: 8px;
        border-radius: 10px;
        background-color: ${color.blue};
        left: 43px;
        top: 30px;
        bottom: 0;
        z-index: 1;
    }
  
  
    & > div {
        margin-bottom: 40px;
    }
`
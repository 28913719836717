import React from "react";

import { color } from "../../design/theme";

import {
    DevGridContainer, DevGridTile, DevGridTileDesc,
    DevGridIcon, DevGridTagContainer, DevGridTag
} from "./devGrid.styles";

const devData = [
    {
        platform: "Web",
        icon: "140001",
        tag: "alpha"
    },
    {
        platform: "Android",
        icon: "android",
        tag: "developing"
    },
    {
        platform: "iOS",
        icon: "apple",
        tag: "developing"
    },
    {
        platform: "Windows",
        icon: "windows",
        tag: "planned"
    },
    {
        platform: "MacOS",
        icon: "apple",
        tag: "planned"
    },
    {
        platform: "Linux",
        icon: "linux",
        tag: "planned"
    }
]

const DevGrid = ({ }) => {
    return (
        <DevGridContainer>
            {
                devData.map(({ platform, icon, tag }) =>  (
                    <DevGridTile key={platform} muted={tag === "planned"}>
                        <DevGridTagContainer>
                            <DevGridTag
                                bgcolor={tag === "alpha" ? color.yellow : tag === "developing" ? color.red : color.blue}
                                color={tag === "alpha" ? color.black : color.white}
                            >{(tag === "developing" ? tag + "..." : tag).toUpperCase()}</DevGridTag>
                        </DevGridTagContainer>
                        <DevGridIcon
                            name={icon}
                            size={40}
                            color={color.black}
                        />
                        <DevGridTileDesc>{platform}</DevGridTileDesc>
                    </DevGridTile>
                ))
            }
        </DevGridContainer>
    )
}

export default DevGrid;
import styled from "styled-components";
import { color, font, radius } from "../../design/theme";
import Icon from "../../assets/icons/icon.component";

export const DevGridContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 20px;
    column-gap: 15px;
`

export const DevGridTile = styled.div`
    height: 110px;
    opacity: ${props => props.muted ? 0.5 : 1};
    box-shadow: inset 0 0 0 7px ${color.black};
    border-radius: ${radius.largeRadius};
    background-color: ${color.white};
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`

export const DevGridTileDesc = styled.div`
    font-family: ${font.interExtraBold};
    color: ${color.black};
    font-size: 22px;
`

export const DevGridIcon = styled(Icon)`
    height: auto;
    width: auto;
    margin-bottom: 3px;
`

export const DevGridTagContainer = styled.div`
    height: 21px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: -7px;
    display: flex;
    justify-content: center;
`

export const DevGridTag = styled.div`
    height: inherit;
    width: auto;
    padding: 5px 15px;
    background-color: ${props => props.bgcolor};
    color: ${props => props.color};
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${font.pulpDisplayMedium};
    font-size: 12px;
    letter-spacing: 0.05em;
    border-radius: 6px;
`
import styled from "styled-components";

export const ModalActionButtonContainer = styled.div`
    width: 100%;
    margin-top: 25px;
  
    ${props => props.singular ? `
        display: flex;
        justify-content: center;
    ` : `
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
    `};
`;
import styled from "styled-components";
import { color, font } from "../../design/theme";

export const MainMenuBar = styled.div`
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: 999;
`

export const MainMenuContainer = styled.div`
    height: auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding: ${props => props.sticky ? "10px" : "30px"} 50px;
    column-gap: 60px;
    background-color: ${props => props.sticky ? "rgba(255, 255, 255, 0.5)" : "transparent"};
    backdrop-filter: ${props => props.sticky ? "blur(30px)" : "none"};
`

export const MainMenuLogo = styled.img`
    height: ${props => props.sticky ? "30px" : "35px"};
    margin-right: auto;
`

export const MainMenuNavPoint = styled.div`
    color: ${color.black};
    font-size: 20px;
    font-family: ${font.pulpDisplayBold}; 
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`
import styled from "styled-components";
import { color, radius } from "../../design/theme";

export const LinearProgressBar = styled.progress`
    width: 100%;
    height: inherit; // alt 6
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: ${props => props.darkmode ? color.gray3 : color.gray4};
    color: ${color.blue};
    border-radius: ${radius.smallRadius};
    
    &::-webkit-progress-bar {
        background-color: ${props => props.darkmode ? color.gray3 : color.gray4};
        border-radius: ${radius.smallRadius};
    }
    &::-webkit-progress-value {
        background-color: ${color.blue};
        border-radius: ${radius.smallRadius};
    }
    
    &::-moz-progress-bar {
        background-color: ${color.blue};
        border-radius: ${radius.smallRadius};
    }
`

export const CircularProgressContainer = styled.div`
    position: relative;
    display: block;
    height: inherit;
    width: inherit;
  
    &>svg:first-child {
        z-index: 3;
    }
    &>svg:nth-child(0 +2n) {
        z-index: 2;
    }
`

export const CircularProgressBar = styled.svg`
    position: absolute;
    transform: rotate(-90deg);
`
import styled from "styled-components";
import { color, font } from "../../design/theme";

export const FooterWrapper = styled.footer`
    background-color: ${color.gray5};
    width: 100vw;
    height: max-content;
    display: flex;
    justify-content: center;
`

export const FooterContainer = styled.div`
    width: 800px;
    max-width: 90%;
    padding: 40px 0;
    display: flex;
    justify-content: flex-start;
`

export const FooterDisclaimer = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    color: ${color.gray3};
    white-space: pre-wrap;
    text-align: left;
  
    & > span:nth-child(2) {
        cursor: pointer;
      
        &:hover {
            opacity: 0.6;
        } 
    }
`

export const FooterSocials = styled.div`
    margin-left: auto;
    display: flex;
`

export const FooterSocialIcon = styled.a`
    height: auto;
    width: auto;
    margin-left: 20px;
`
import { createGlobalStyle } from "styled-components";
import { color } from "../design/theme";

import PulpDisplayBoldWoff2 from "../assets/fonts/PulpDisplay-Bold.woff2";
import PulpDisplayBoldWoff from "../assets/fonts/PulpDisplay-Bold.woff";
import PulpDisplayBoldTtf from "../assets/fonts/PulpDisplay-Bold.ttf";
import PulpDisplayBoldOtf from "../assets/fonts/PulpDisplay-Bold.otf";

import PulpDisplayMediumWoff2 from "../assets/fonts/PulpDisplay-Medium.woff2";
import PulpDisplayMediumWoff from "../assets/fonts/PulpDisplay-Medium.woff";
import PulpDisplayMediumTtf from "../assets/fonts/PulpDisplay-Medium.ttf";
import PulpDisplayMediumOtf from "../assets/fonts/PulpDisplay-Medium.otf";

import InterBlackWoff2 from "../assets/fonts/Inter-Black.woff2";
import InterBlackWoff from "../assets/fonts/Inter-Black.woff";
import InterBlackTtf from "../assets/fonts/Inter-Black.ttf";
import InterBlackOtf from "../assets/fonts/Inter-Black.otf";

import InterExtraBoldWoff2 from "../assets/fonts/Inter-ExtraBold.woff2";
import InterExtraBoldWoff from "../assets/fonts/Inter-ExtraBold.woff";
import InterExtraBoldTtf from "../assets/fonts/Inter-ExtraBold.ttf";
import InterExtraBoldOtf from "../assets/fonts/Inter-ExtraBold.otf";

import InterBoldItalicWoff2 from "../assets/fonts/Inter-BoldItalic.woff2";
import InterBoldItalicWoff from "../assets/fonts/Inter-BoldItalic.woff";
import InterBoldItalicTtf from "../assets/fonts/Inter-BoldItalic.ttf";
import InterBoldItalicOtf from "../assets/fonts/Inter-BoldItalic.otf";

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        scrollbar-width: none;
        margin: 0;
        padding: 0;
    }
    *, *::before, *::after {
        box-sizing: border-box;
    }
    *:fullscreen {
        position: fixed;
        top: 0; 
        right: 0; 
        bottom: 0; 
        left: 0;
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    html {
      overflow-y: hidden;
      overflow-x: hidden;
    }
    body {
        // position: fixed;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        width: 100vw;
        overflow-scrolling: touch;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        text-rendering: optimizeLegibility;
        overflow-clip: auto;
        text-align: center;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -moz-osx-font-smoothing: auto;
        -webkit-font-smoothing: subpixel-antialiased;
    }
    #root {
        width: inherit;
        height: inherit;
        // overflow: scroll;
    }
    
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
        background-color: transparent;
    }
    ::selection {
        background: ${color.black};
        color: ${color.white};
        border-radius: 10px;
    }
    ::placeholder, ::-webkit-input-placeholder {
        overflow: visible;
        // color: ${props => props.darkmode ? `${color.gray3} !important` : `${color.gray4} !important`};
        opacity: 1;
    }
    ::-moz-placeholder, :-moz-placeholder, :-ms-input-placeholder {
        overflow: visible;
        // color: ${props => props.darkmode ? `${color.gray3} !important` : `${color.gray4} !important`};
        opacity: 1;
    }
    a {
        text-decoration: none;
        color: ${color.blue};
    }
    span {
        height: auto;
        width: auto;
    }
    input::placeholder {
        overflow: visible;
    }
    img {
        user-select: none;
        -webkit-user-drag: none;
    }
    
    @font-face {
        font-family: "Pulp Display Bold";
        font-style: normal;
        font-weight: 700;
        font-display: block;
        src: local("Pulp Display Bold"), local("PulpDisplay-Bold"), 
             url(${PulpDisplayBoldWoff2}) format("woff2"),
             url(${PulpDisplayBoldWoff}) format("woff"),
             url(${PulpDisplayBoldTtf}) format("truetype"),
             url(${PulpDisplayBoldOtf}) format("opentype");
    }
    @font-face {
        font-family: "Pulp Display Medium";
        font-style: normal;
        font-weight: 500;
        font-display: block;
        src: local("Pulp Display Medium"), local("PulpDisplay-Medium"),
             url(${PulpDisplayMediumWoff2}) format("woff2"),
             url(${PulpDisplayMediumWoff}) format("woff"),
             url(${PulpDisplayMediumTtf}) format("truetype"),
             url(${PulpDisplayMediumOtf}) format("opentype");
    }
    @font-face {
        font-family: "Inter Black";
        font-style: normal;
        font-weight: 900;
        font-display: block;
        src: local("Inter Black"), local("Inter-Black"),
             url(${InterBlackWoff2}) format("woff2"),
             url(${InterBlackWoff}) format("woff"),
             url(${InterBlackTtf}) format("truetype");
             url(${InterBlackOtf}) format("opentype");
    }
    @font-face {
        font-family: "Inter Extra Bold";
        font-style: normal;
        font-weight: 800;
        font-display: block;
        src: local("Inter Extra Bold"), local("Inter-ExtraBold"),
             url(${InterExtraBoldWoff2}) format("woff2"),
             url(${InterExtraBoldWoff}) format("woff"),
             url(${InterExtraBoldTtf}) format("truetype"),
             url(${InterExtraBoldOtf}) format("opentype");
    }
    @font-face {
        font-family: "Inter Bold Italic";
        font-style: italic;
        font-weight: 700;
        font-display: block;
        src: local("Inter Bold Italic"), local("Inter-BoldItalic"),
             url(${InterBoldItalicWoff2}) format("woff2"),
             url(${InterBoldItalicWoff}) format("woff"),
             url(${InterBoldItalicTtf}) format("truetype"),
             url(${InterBoldItalicOtf}) format("opentype");
    }
`

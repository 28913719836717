import React, { Fragment } from "react";

import { BrandGridTitle, BrandGridContainer, BrandGridTile } from "./brandGrid.styles";

const brandData = [
    { name: "Aargauer Zeitung" },
    { name: "Appenzeller Zeitung" },
    { name: "Badener Tagblatt" },
    { name: "Bündner Tagblatt" },
    { name: "bz Basel" },
    { name: "Grenchner Tagblatt" },
    { name: "Limmattaler Zeitung" },
    { name: "Luzerner Zeitung" },
    { name: "Nidwaldner Zeitung" },
    { name: "Obwaldner Zeitung" },
    { name: "Oltner Tagblatt" },
    { name: "Schweiz am Wochenende" },
    { name: "Solothurner Zeitung" },
    { name: "St.Galler Tagblatt" },
    { name: "Südostschweiz" },
    { name: "Thurgauer Zeitung" },
    { name: "Toggenburger Tagblatt" },
    { name: "Urner Zeitung" },
    { name: "Wiler Zeitung" },
    { name: "Zuger Zeitung" },
]
const transformName = (string) => {
    return string.toLowerCase().replace("ü", "ue").replaceAll(" ", "-")
}


const BrandGrid = ({ }) => {
    return (
        <Fragment>
            <BrandGridTitle>
                <span>{"Who's already on board? 🚂"}</span>
            </BrandGridTitle>
            <BrandGridContainer>
                {
                    brandData.map(({ name }) => {
                        const srcString = `content/brands/logos/${transformName(name)}.svg`;
                        return (
                            <BrandGridTile key={name}>
                                <img
                                    src={`https://storage.googleapis.com/reavide/${srcString}`}
                                    alt={name}
                                />
                            </BrandGridTile>
                        )
                    })
                }
            </BrandGridContainer>
        </Fragment>
    )
}

export default BrandGrid;
import React, { useState, useRef, useEffect } from "react";

import { color } from "../../design/theme";
import { textColor, countryCodeEmoji } from "../utils";

import {
    DropdownWrapper,
    DropdownContainer,
    DropdownItem,
    DropdownIcon,
    DropdownDesc,
    DropdownCountries
} from "./dropdown.styles";

const Dropdown = ({ darkmode, lang, activeType, callback, contentData }) => {
    const [active, setActive] = useState(false);
    const ref = useRef(null);

    const handleClick = (event, type) => {
        event.stopPropagation();
        if(type) {
            callback(type)
            setActive(!active)
        }
        if(!ref.current?.contains(event.target)) setActive(false);
    }

    const getCountries = (lang = "en") => {
        const A = 65
        const Z = 90
        const countryName = new Intl.DisplayNames([lang], { type: "region" });
        const countriesArr = [];
        const countriesObj = {};
        for(let i=A; i<=Z; ++i) {
            for(let j=A; j<=Z; ++j) {
                let code = String.fromCharCode(i) + String.fromCharCode(j)
                let name = countryName.of(code)
                if (code !== name) countriesArr.push({ code: code.toLowerCase(), name })
            }
        }
        countriesArr.forEach((item) => { countriesObj[item.name] = item.code });
        return [countriesObj, countriesArr]
    }
    const excludedCountryCodes = ["dy", "hv", "zr", "an", "ez", "eu", "fx", "dd", "bu", "qo", "xa", "xb", "su", "cs", "yu", "tp", "uk", "zz", "nh", "vd", "yd", "rh"];

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)

        return () => document.removeEventListener("mousedown", handleClick)
    }, [ref])

    return (
        <DropdownWrapper ref={ref}>
            <DropdownContainer darkmode={darkmode} active={active}>
                <DropdownCountries
                    onChange={(event) => callback({ code: getCountries()[0][event.target.value.slice(5)], name: event.target.value.slice(5)})}
                    defaultValue={"Please Select"}
                >
                    <option>Please Select</option>
                    {
                        getCountries()[1].sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
                            if(!excludedCountryCodes.includes(item.code)) {
                                return <option key={item.code}>{countryCodeEmoji(item.code) + " " + item.name}</option>
                            } else return null;
                        })
                    }
                </DropdownCountries>
                {
                    Object.values(contentData).map(({ type, icon, desc }) => {
                        if(type === activeType || active) {
                            return (
                                <DropdownItem
                                    key={type}
                                    onClick={(event) => handleClick(event, type)}
                                    darkmode={darkmode}
                                >
                                    <DropdownIcon
                                        name={icon}
                                        size={30}
                                        color={(active && type === activeType) ? color.blue : textColor(darkmode)}
                                    />
                                    <DropdownDesc darkmode={darkmode} active={active && type === activeType}>
                                        <span>{desc[lang]}</span>
                                    </DropdownDesc>
                                    {
                                        type === activeType ? (
                                            <DropdownIcon
                                                name={active ? "checkmark" : "enter"}
                                                size={30}
                                                color={active ? color.blue : color.gray3}
                                                secondary={"true"}
                                            />
                                        ) : null
                                    }
                                </DropdownItem>
                            )
                        }
                    })
                }
            </DropdownContainer>
        </DropdownWrapper>
    )
}

export default Dropdown;
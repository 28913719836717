import styled from "styled-components";
import { color, radius, font } from "../../design/theme";
import { textColor } from "../utils";
import Icon from "../../assets/icons/icon.component";

export const DropdownWrapper = styled.div`
    height: 50px;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    z-index: 10;
`

export const DropdownContainer = styled.div`
    background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    border-radius: ${radius.smallRadius};
    overflow: hidden;
    position: absolute;
    height: max-content;
    width: 100%;
    z-index: inherit;
    top: 0;
    left: 0;
  
    ${props => props.active ? `
        box-shadow: 0 1px 5px 0 ${props.darkmode ? color.black : color.gray3};
    ` : null}
  
    &::after {
        content: "";
        height: 100%;
        border-radius: inherit;
        border: 1px solid ${props => props.active ? props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)" : "transparent"};
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
`

export const DropdownItem = styled.div`
    height: 50px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    z-index: inherit;
    pointer-events: none;
    
    &:hover {
        background-color: ${props => props.darkmode ? color.blueActiveDark : color.blueActiveLight};
    }
`

export const DropdownIcon = styled(Icon)`
    height: auto;
    width: auto;
    justify-content: center;
    transform: ${props => props.name === "enter" ? "rotateY(180deg)" : "0"};
    margin: ${props => props.secondary ? "0 0 0 auto" : "0"};
    flex-shrink: 0;
`

export const DropdownDesc = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 20px;
    color: ${props => props.active ? color.blue : textColor(props.darkmode)};
    letter-spacing: 0.01em;
    user-select: none;
    margin-left: 15px;
`

export const DropdownCountries = styled.select`
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1000;
    cursor: pointer;
`
import styled from "styled-components";

export const ScrollProgressContainer = styled.div`
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    z-index: 5;
    display: inline-flex;
    flex-direction: column;
`
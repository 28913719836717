import styled from "styled-components";
import { font, color } from "../design/theme";

import textEmphasis from "../assets/images/text-emphasis.svg";

export const BackgroundImage = styled.div`
    height: auto;
    width: auto;
    position: fixed;
    z-index: -1;
  
    & img, picture, source, div {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`

export const HeroContainer = styled.section`
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  
    & > svg {
        cursor: pointer;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        bottom: 30px;
        left: 0;
        right: 0;
        opacity: 0.3;
    }
`

export const HeroContentContainer = styled.div`
    width: max-content;
    height: max-content;
    position: relative;
    margin-left: -150px;
`

export const HeroText = styled.h1`
    font-family: ${font.interBlack};
    font-size: 70px;
    color: ${color.black};
    text-align: left;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    line-height: 75px;
    letter-spacing: normal;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  
    & > span:last-of-type {
        width: max-content;
        height: 90px;
        background-image: linear-gradient(90deg, #3873F7, #590AEF, #C73FE7);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        user-select: none;
    }
`

export const HeroCTAButton = styled.button`
    height: 50px;
    border-radius: 14px;
    width: max-content;
    background-color: ${color.blue};
    font-family: ${font.pulpDisplayBold};
    font-size: 20px;
    color: ${color.white};
    padding: 0 45px;
    display: flex;
    border: none;
    align-items: center;
    margin-top: 20px;
    letter-spacing: 0.02em;
    cursor: pointer;
    user-select: none;
  
    & > span:first-of-type {
        margin-right: 10px;
    }
  
    &:hover {
        opacity: 0.85;
    }
    &:active {
        transform: scale(0.98);
    }
`

export const HeroPhoneImage = styled.div`
    height: 750px;
    top: -150px;
    right: -320px;
    width: auto;
    position: absolute;
    
    & img, picture, source, div {
        height: 750px !important;
        width: auto !important;
    }
`

export const MainContentWrapper = styled.div`
    width: 100vw;
    padding: 50px 0;
    height: max-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #FFFFFF00 5%, #FFFFFF 50%);
    scroll-margin: 80px;
`

export const MainContentContainer = styled.section`
    width: 800px;
    max-width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const StoryParagraph = styled.div`
    width: 760px;
    max-width: 100%;
    font-family: ${font.interExtraBold};
    color: ${color.black};
    text-align: left;
    font-size: 50px;
    white-space: pre-wrap;
    margin-bottom: 100px;
    line-height: 65px;
`

export const StoryPartLowkey = styled.span`
    color: ${color.gray3}
`

export const StoryPartEmphasis = styled.span`
    background-color: ${color.blue};
    color: ${color.white}
`

export const StoryPartUnderlined = styled.span`
    position: relative;
  
    &::after {
        content: "";
        height: 40px;
        width: 250px;
        background: url(${textEmphasis}) no-repeat;
        background-size: contain;
        position: absolute;
        z-index: 100;
        top: 55px;
        left: 0;
    }
`
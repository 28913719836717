import React, { Fragment } from "react";

import {
    CustomButtonContainer,
    CustomButtonIconContainer,
    CustomButtonIcon,
    CustomButtonDescContainer,
    CustomButtonFileInput,
    CustomButtonLoadingContainer,
    CustomButtonLoading
} from "./customButton.styles";

const CustomButton = ({ desc, solid = false, disabled, service, type = "button", loading, height,
                          size, icon, color, active, width, center, contentColor, callback, upload, spinIconOnLoad }) => {

    const handleOnClick = (event, key) => {
        event.stopPropagation();
        if(upload) {
            if(key === "upload" && event.target.files[0]) callback(event.target.files[0])
        } else if(type !== "submit" && !disabled && callback) callback()
    }

    return (
        <CustomButtonContainer
            onClick={(event) => !upload ? handleOnClick(event) : null}
            type={type}
            solid={solid}
            disabled={disabled}
            desc={desc}
            size={size}
            color={color}
            active={active}
            width={width}
            height={height}
            center={center}
            loading={loading ? "true" : null}
            contentColor={contentColor}
        >
            {
                loading && !spinIconOnLoad ? (
                    <CustomButtonLoadingContainer>
                        <CustomButtonLoading />
                    </CustomButtonLoadingContainer>
                ) : (
                    <Fragment>
                        {
                            upload ? (
                                <CustomButtonFileInput
                                    type={"file"}
                                    title={""}
                                    onChange={event => handleOnClick(event, "upload")}
                                    accept={"image/png, image/jpeg, image/jpg, image/webp"}
                                    multiple={false}
                                />
                            ) : null
                        }
                        {
                            (icon && service) ? (
                                <CustomButtonIconContainer>
                                    <CustomButtonIcon
                                        name={icon}
                                        size={20}
                                        color={solid ? contentColor ? contentColor : "white" : contentColor ? contentColor : color}
                                    />
                                </CustomButtonIconContainer>
                            ) : null
                        }
                        {
                            desc ? (
                                <CustomButtonDescContainer service={service}>
                                    <span>{desc}</span>
                                </CustomButtonDescContainer>
                            ) : null
                        }
                        {
                            (icon && !service) ? (
                                <CustomButtonIconContainer desc={desc}>
                                    <CustomButtonIcon
                                        name={icon}
                                        size={desc ? 20 : 25}
                                        color={solid ? contentColor ? contentColor : "white" : contentColor ? contentColor : color}
                                        loading={loading ? 1 : 0}
                                        spinonload={spinIconOnLoad}
                                    />
                                </CustomButtonIconContainer>
                            ) : null
                        }
                    </Fragment>
                )
            }
        </CustomButtonContainer>
    )
}

export default CustomButton;
import styled from "styled-components";
import { radius, color, font } from "../../design/theme";

export const BrandGridTitle = styled.h2`
    font-family: ${font.pulpDisplayBold};
    font-size: 55px;
    color: ${color.black};
    margin-bottom: 25px;
    margin-top: 80px;
`

export const BrandGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 15px;
    column-gap: 15px;
    margin-bottom: 30px;
    width: 100%;
`

export const BrandGridTile = styled.div`
    width: 100%;
    height: 110px;
    padding: 0 10px;
    flex-grow: 0;
    border-radius: ${radius.smallRadius};
    overflow: hidden;
    filter: grayscale(1);
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &:hover {
        opacity: 1;
        filter: grayscale(0);
        box-shadow: 3px 3px 10px 5px ${color.gray5};
    }
    
    & img {
        object-fit: cover;
        width: 100%;
        user-select: none;
    }
`
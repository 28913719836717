import React from "react";

import { color } from "../../design/theme";

import {
    MainCardCTAWrapper, MainCardCTAContainer, MainCardCTAContent,
    MainCardCTABackground, MainCardCTATitle, MainCardCTADesc, MainCardCTASquareLogo
} from "./mainCardCTA.styles";

import mainCTABg from "../../assets/images/cta-bg.svg";
import reavideIcon from "../../assets/images/reavide-icon.svg";
import CustomButton from "../customButton/customButton.component";

const MainCardCTA = ({ callback }) => {

    return (
        <MainCardCTAWrapper>
            <MainCardCTAContainer>
                <MainCardCTAContent>
                    <MainCardCTATitle>
                        <MainCardCTASquareLogo src={reavideIcon} alt={"reavide Icon"} />
                        <span>Get reavide News!</span>
                    </MainCardCTATitle>
                    <MainCardCTADesc>Be the first to know when we launch to the public later this year. If you’re a student you’ll be hearing from us even sooner! </MainCardCTADesc>
                    <CustomButton
                        callback={callback}
                        desc={"Are you ready?"}
                        color={color.blue}
                        width={250}
                        height={40}
                        size={"small"}
                        icon={"arrow"}
                        solid
                    />
                </MainCardCTAContent>
                <MainCardCTABackground>
                    <img src={mainCTABg} alt={"CTA Decoration"} />
                </MainCardCTABackground>
            </MainCardCTAContainer>
        </MainCardCTAWrapper>
    )
}

export default MainCardCTA;
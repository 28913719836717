import React, { useState, useEffect, useCallback, Fragment } from "react";

import { ScrollProgressContainer } from "./scrollProgress.styles";
import Progress from "../progress/progress.component";

const ScrollProgress = ({ target, setPageProgress }) => {
    const [scrollProgress, setReadingProgress] = useState(0);
    let timeout;

    const scrollListener = useCallback((event) => {
        if(event) event.stopPropagation();
        if (!target) return;

        const totalHeight = target.clientHeight - target.offsetTop - (window.innerHeight);
        const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || window.scrollY || document.scrollingElement.scrollTop || 0;
        if (windowScrollTop === 0) {
            return setReadingProgress(0);
        }
        if (windowScrollTop > totalHeight) {
            return setReadingProgress(100);
        }

        setReadingProgress(
            Number(
                Math.round(
                    parseFloat(((windowScrollTop / totalHeight) * 100) + 'e' + 3)
                ) + 'e-' + 3
            )
        )
    }, [target]);
    const scrollListenerController = (event) => {
        if(timeout) window.cancelAnimationFrame(timeout);
        timeout = window.requestAnimationFrame(() => scrollListener(event));
    }

    const adjustedScrollProgress = scrollProgress.toFixed(0);

    useEffect(() => {
        const observer = new MutationObserver(() => scrollListener(null));
        if(target) observer.observe(target, { attributes: true, childList: true, subtree: true });
    }, [target])

    useEffect(() => {
        window.addEventListener("scroll", scrollListenerController, { passive: true, capture: true });

        return () => window.removeEventListener("scroll", scrollListenerController, { passive: true, capture: true });
    }, [scrollListener, adjustedScrollProgress, setPageProgress, scrollProgress]);

    return (
        <Fragment>
            <ScrollProgressContainer>
                <Progress
                    value={scrollProgress}
                    type={"linear"}
                />
            </ScrollProgressContainer>
        </Fragment>
    );
};

export default ScrollProgress;
import React from "react";

import { LandingTimelineWrapper } from "./landingTimeline.styles";

import LandingTimelineCard from "../landingTimelineCard/landingTimelineCard.component";
import DevGrid from "../devGrid/devGrid.component";

const LandingTimeline = ({ }) => {
    return (
        <LandingTimelineWrapper>
            <LandingTimelineCard
                title={"Foundations"}
                desc={"At first there was an idea. Next there were a solid product and happy publisher partnerships. Quite simple really. Except it wasn’t. Like, at all. But why did we even embark on this journey? Because news has the important potential to be better 🚀."}
                quote={"Our mission is to provide a platform for the curious. For those who value quality journalism, but don’t feel attached to any particular brand. For those who demand a modern experience. For those who appreciate different viewpoints."}
                icon={"check"}
            />
            <LandingTimelineCard
                title={"Development"}
                desc={"We are currently in the development stage of our diverse array of clients to provide you with the best experience possible 🙌."}
                icon={"settings"}
                current={"true"}
            >
                <DevGrid />
            </LandingTimelineCard>
            <LandingTimelineCard
                title={"Beta Launch ⚙︎"}
                desc={"We will start at different universities in German-speaking Switzerland. Students 🧑‍🎓 will then get special access 🌟 and conditions to our platform so watch out! 👀"}
                icon={"more"}
            />
            <LandingTimelineCard
                title={"Public rollout"}
                desc={"Later this year we will make reavide News available to the public with a fair price and an equitable distribution that will help fuel future production of high quality journalism. Of course there will also be a trial, so why not sign up and get notified to try it out? 🔔"}
                icon={"more"}
                admin={"true"}
            />
        </LandingTimelineWrapper>
    )
}

export default LandingTimeline;
import React, { useState } from "react";
import { color } from "../../design/theme";

import {
    FooterWrapper, FooterContainer, FooterDisclaimer,
    FooterSocials, FooterSocialIcon
} from "./footer.styles";

import Icon from "../../assets/icons/icon.component";
import Modal from "../modal/modal.component";

const socials = ["instagram", "twitter", "facebook"]

const Footer = ({ }) => {
    const[legalOpen, setLegalOpen] = useState(false);

    const activateModal = (event) => {
        event?.stopPropagation();
        setLegalOpen(true);
    }

    return (
        <FooterWrapper>
            <FooterContainer>
                <FooterDisclaimer>
                    <span>{"Made with ❤️  in Basel.\nCopyright © reavide KlG.  |  "}</span>
                    <span onClick={(event) => activateModal(event)}>{"Legal"}</span>
                </FooterDisclaimer>
                <FooterSocials>
                    {
                        socials.map((item) => (
                            <FooterSocialIcon href={`https://reavi.de/${item}`} target={"_blank"} key={item}>
                                <Icon
                                    color={color.blue}
                                    name={item}
                                    size={40}
                                />
                            </FooterSocialIcon>
                        ))
                    }
                </FooterSocials>

                {
                    legalOpen ? (
                        <Modal
                            type={"terms"}
                            title={"Terms & Privacy"}
                            onDismiss={() => setLegalOpen(!legalOpen)}
                            desc={"Contact address\n" +
                                "reavide KlG\n" +
                                "Oltingerstrasse 33\n" +
                                "4055 Basel\n" +
                                "Switzerland\n" +
                                "E-Mail: info@reavide.com\n" +
                                " \n" +
                                "Authorized representatives\n" +
                                "Leo Scheider, Co-Managing Director\n" +
                                "Tomas Koturenka, Co-Managing Director\n" +
                                " \n" +
                                "Commercial register entry\n" +
                                "Registered company name: reavide KlG\n" +
                                "Commercial register no.: CHE-274-359-311\n" +
                                "Place of jurisdiction is Basel-Stadt, Switzerland.\n" +
                                "\n" +
                                "\n" +
                                "Disclaimer\n" +
                                "All information on our website has been carefully checked. We make every effort to ensure that the material contained in our web site is current, complete and correct. Nevertheless, the occurrence of errors cannot be completely excluded, which means that we cannot guarantee the completeness, accuracy and timeliness of information, including journalistic and editorial.liability claims against the operator for damages of a material or immaterial nature arising from access to or use or non-use of the published information, through misuse of the connection or technical failures are excluded. We expressly reserve the right to change, supplement or delete parts of the pages or the entire offer without special notice or to temporarily or permanently cease publication.\n" +
                                "\n" +
                                "The operator also assumes no responsibility or liability for the content and availability of third-party websites that can be accessed via external links on this website. The operators of the linked sites are solely responsible for their content. The publisher thus expressly distances itself from all third-party content that may be relevant under criminal or liability law or that may offend common decency. The access and use of such websites is at the own risk of the respective user.\n" +
                                "\n" +
                                " \n" +
                                "Copyrights\n" +
                                "The copyrights and all other rights to the content, images, photos or other files on this website, belong exclusively to the company reavide KlG or the specifically named rights holders. The written consent of the copyright holder must be obtained in advance for the reproduction of any elements.\n" +
                                "Anyone who commits a copyright infringement without the consent of the respective copyright holder may be liable to prosecution and possibly to damages.\n" +
                                "\n" +
                                "General privacy policy\n" +
                                "The responsible body in terms of data protection laws, in particular the EU General Data Protection Regulation (GDPR), is the above-mentioned operator of this website.\n" +
                                "\n" +
                                "Based on Article 13 of the Swiss Federal Constitution and the federal data protection regulations (Data Protection Act, DSG), every person has the right to protection of their privacy as well as protection against misuse of their personal data. The operator of these pages takes the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the legal data protection regulations as well as this data protection declaration.\n" +
                                "\n" +
                                "We process personal data in accordance with Swiss data protection law. In other respects, we process personal data - to the extent and insofar as the EU Data Protection Regulation is applicable - in accordance with the legal bases in connection with Art. 6 (1) DSGVO. We process personal data for the duration required for the respective purpose or purposes. In the case of longer-lasting retention obligations due to legal and other obligations to which we are subject, we restrict the processing accordingly.\n" +
                                "\n" +
                                "In cooperation with our hosting providers, we make every effort to protect the databases as well as possible against unauthorized access, loss, misuse or falsification.\n" +
                                "We would like to point out that data transmission on the Internet (e.g. communication by e-mail) can have security gaps. A complete protection of data against access by third parties is not possible.\n" +
                                "\n" +
                                "By using this website, you consent to the collection, processing and use of data in accordance with the following description. This website can generally be visited without registration. Data such as pages accessed or names of files accessed, date and time are stored on the server for statistical purposes without this data being directly related to your person. Personal data, in particular name or e-mail address, are collected as far as possible on a voluntary basis. No data will be passed on to third parties without your consent.\n" +
                                "\n" +
                                "\n" +
                                "Privacy policy for newsletter data\n" +
                                "If you would like to receive the newsletter offered on this website, we require an e-mail address from you as well as information that allows us to verify that you are the owner of the e-mail address provided and that you agree to receive the newsletter. Further data will not be collected. We use this data exclusively for sending the requested information and do not pass it on to third parties.\n" +
                                "\n" +
                                "You can revoke your consent to the storage of the data, the e-mail address and their use for sending the newsletter at any time, for example via the \"unsubscribe link\" in the newsletter.  \n" +
                                "\n" +
                                "\n" +
                                "Privacy policy for cookies\n" +
                                "This website uses cookies. These are small text files that make it possible to store specific information related to the user on the user's terminal device while the user is using the website. Cookies make it possible, in particular, to determine the frequency of use and number of users of the pages, to analyze behavior patterns of page use, but also to make our offer more customer-friendly. Cookies remain stored beyond the end of a browser session and can be retrieved when you visit the site again. If you do not wish this to happen, you should set your Internet browser so that it refuses to accept cookies.\n" +
                                "\n" +
                                "A general objection to the use of cookies for online marketing purposes can be declared for a large number of services, especially in the case of tracking, via the U.S. site http://www.aboutads.info/choices/ or the EU site http://www.youronlinechoices.com/. Furthermore, the storage of cookies can be achieved by disabling them in the browser settings. Please note that in this case not all functions of this online offer can be used.\n" +
                                "\n" +
                                "\n" +
                                "Privacy policy for SSL/TLS encryption\n" +
                                "This website uses SSL/TLS encryption for security reasons and to protect the transmission of confidential content, such as inquiries that you send to us as the site operator. You can recognize an encrypted connection by the fact that the address line of the browser changes from \"http://\" to \"https://\" and by the lock symbol in your browser line.\n" +
                                "If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.\n" +
                                "\n" +
                                "\n" +
                                "Privacy policy for Twitter\n" +
                                "This website uses functions of Twitter, Inc, 1355 Market St, Suite 900, San Francisco, CA 94103, USA. When you call up our pages with Twitter plug-ins, a connection is established between your browser and the servers of Twitter. In the process, data is already transferred to Twitter. If you have a Twitter account, this data can be linked to it. If you do not wish this data to be associated with your Twitter account, please log out of Twitter before visiting our site. Interactions, in particular clicking on a \"re-tweet\" button, are also passed on to Twitter. You can learn more at https://twitter.com/privacy.\n" +
                                "\n" +
                                "\n" +
                                "Privacy policy for Instagram\n" +
                                "On our website, functions of the service Instagram are integrated. These functions are offered by Instagram Inc, 1601 Willow Road, Menlo Park, CA, 94025, USA integrated. If you are logged into your Instagram account, you can link the content of our pages to your Instagram profile by clicking on the Instagram button. This allows Instagram to associate the visit to our pages with your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by Instagram.\n" +
                                "For more information, please see Instagram's privacy policy: http://instagram.com/about/legal/privacy.\n" +
                                "\n" +
                                "\n" +
                                "Privacy policy for Google\n" +
                                "This website uses Google Analytics, a web analytics service provided by Google Ireland Limited. If the data controller on this website is located outside the European Economic Area or Switzerland, Google Analytics data processing is carried out by Google LLC. Google LLC and Google Ireland Limited are hereinafter referred to as \"Google\".\n" +
                                "\n" +
                                "The statistics obtained enable us to improve our offer and make it more interesting for you as a user. This website also uses Google Analytics for a cross-device analysis of visitor flows, which is carried out via a user ID. If you have a Google user account, you can deactivate the cross-device analysis of your usage in the settings there under \"My data\", \"Personal data\".\n" +
                                "\n" +
                                "Google Analytics uses cookies. The information generated by the cookie about your use of this website is usually transmitted to a Google server in the USA and stored there. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website.\n" +
                                "\n" +
                                "his website may also continue to use Google Maps for embedding maps, Google Invisible reCAPTCHA for protection against bots and spam, and YouTube for embedding videos.\n" +
                                "These services of the American Google LLC use cookies, among other things, and as a result, data is transferred to Google in the USA, although we assume that no personal tracking takes place in this context solely through the use of our website.\n" +
                                "\n" +
                                "Google has undertaken to ensure adequate data protection in accordance with the US-European and the US-Swiss Privacy Shield. Further information can be found in Google's privacy policy.\n" +
                                "\n" +
                                "\n" +
                                "Changes\n" +
                                "We may amend this privacy policy at any time without prior notice. The current version published on our website applies. Insofar as the data protection declaration is part of an agreement with you, we will inform you of the change by e-mail or other suitable means in the event of an update.\n" +
                                "\n" +
                                "\n" +
                                "Questions to the data protection officer and contact\n" +
                                "If you have any questions about data protection, please write us an e-mail or contact directly the responsible person in our organization listed for data protection at the beginning of the privacy policy.\n" +
                                "\n" +
                                "However, we hereby object to the use of contact data published within the framework of the imprint obligation for the purpose of sending advertising and information material that has not been expressly requested. The operators of the pages expressly reserve the right to take legal action in the event of the unsolicited sending of advertising information, such as spam e-mails.\n" +
                                "\n" +
                                "\n" +
                                "This imprint, as well as the privacy policy were created with the help of SwissAnwalt.\n" +
                                "Copyright © reavide KlG"}
                        />
                    ) : null
                }
            </FooterContainer>
        </FooterWrapper>
    )
}

export default Footer;
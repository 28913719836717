import styled from "styled-components";
import { color, font } from "../../design/theme";

export const LandingFeaturesTitle = styled.h2`
    font-family: ${font.pulpDisplayBold};
    font-size: 55px;
    color: ${color.black};
    margin-bottom: 15px;
    margin-top: 70px;
`

export const LandingFeaturesDesc = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 24px;
    color: ${color.gray3};
    margin-bottom: 25px;
    text-align: left;
    width: 100%;
`

export const LandingFeaturesList = styled.div`
    width: 80%;
    height: auto;
    margin-bottom: 15px;
`

export const LandingFeaturesListItem = styled.div`
    height: max-content;
    display: flex;
    flex-direction: row;
    font-family: ${font.pulpDisplayMedium};
    color: ${color.black};
    font-size: 24px;
    margin-bottom: 10px;
    align-items: center;
    text-align: left;
  
    & > svg {
        flex-shrink: 0;
    } 
      
    & > span {
        margin-left: 15px;
    }
`
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

import { color } from "../design/theme";
import { GlobalStyle } from "../styles/global.styles";
import {
    BackgroundImage, HeroContainer, HeroContentContainer, HeroText,
    HeroCTAButton, HeroPhoneImage, MainContentContainer, StoryParagraph,
    StoryPartLowkey, StoryPartEmphasis, StoryPartUnderlined, MainContentWrapper
} from "../styles/index.styles";

import Icon from "../assets/icons/icon.component";
import Modal from "../components/modal/modal.component";
import ModalCapture from "../components/modalCapture/modalCapture.component";
import Navigation from "../components/navigation/navigation.component";
import MainCardCTA from "../components/mainCardCTA/mainCardCTA.component";
import BrandGrid from "../components/brandGrid/brandGrid.component";
import LandingTimeline from "../components/landingTimeline/landingTimeline.component";
import LandingFeatures from "../components/landingFeatures/landingFeatures.component";
import Footer from "../components/footer/footer.component";

const IndexPage = ({ }) => {
    const[modalActive, setModalActive] = useState(false);
    const[modalComplete, setModalComplete] = useState(false);

    const activateModal = (event) => {
        event?.stopPropagation();
        setModalActive(true);
    }

    return (
        <main>
            <Helmet htmlAttributes={{ lang : "en" }}>
                <title>reavide | Coming Soon...</title>
                <meta name={"description"} content={"Coming soon. Make sure to sign up so you won't miss our launch. We assure you that the wait will be worth it - made in Switzerland."}/>

                <meta name="hostname" content="reavide.com" />
                <meta name="expected-hostname" content="reavide.com" />
                <meta name="referrer" content="strict-origin-when-cross-origin" />
                <meta property="al:web:url" content={"https://reavi.de/"} />
                <meta name="author" content={"reavide"} />
                <meta name="color-scheme" content={"light"} />

                <link rel="publisher" href="https://reavi.de/twitter" />
                <link rel="shortlink" href={"https://reavi.de/"} />
                <link rel="preconnect" href="https://reavi.de" />
                <link rel="dns-prefetch" href="https://reavi.de" />
                <link rel="preconnect" href="https://storage.googleapis.com" />
                <link rel="dns-prefetch" href="https://storage.googleapis.com" />

                <link rel="canonical" href="https://www.reavide.com/" />
            </Helmet>

            <GlobalStyle />
            <BackgroundImage>
                <StaticImage
                    src={"../assets/images/landing-bg.png"}
                    alt={"Holographic Background Image"}
                    placeholder={"blurred"}
                    loading={"eager"}
                />
            </BackgroundImage>

            {
                modalActive ? (
                    <Modal
                        title={modalComplete ? "Thank you so much! 😊" : "Stay in the loop! 🚀"}
                        desc={modalComplete ? "You'll be the first to know when we go live soon! In the meantime, why not follow us @reavide? 🍀✌️" : "We'll notify you as soon as we launch our beta, starting in Switzerland 🇨🇭. Students will get special access, so watch out and get on board. 🔐"}
                        onDismiss={() => setModalActive(!modalActive)}
                        type={modalComplete ? "prompt" : null}
                        icon={"check"}
                    >
                        {
                            !modalComplete ? (
                                <ModalCapture
                                    onDismiss={() => setModalActive(!modalActive)}
                                    callback={() => setModalComplete(true)}
                                />
                            ) : null
                        }
                    </Modal>
                ) : null
            }

            <Navigation callback={activateModal} />

            <HeroContainer id={"#hero"}>
                <HeroContentContainer>
                    <HeroText>
                        <span>{"One Subscription.\nOne Design.\nOne App.\n"}</span>
                        <span>{"All your News."}</span>
                    </HeroText>
                    <HeroCTAButton onClick={(event) => activateModal(event)}>
                        <span>{"Get reavide"}</span>
                        <Icon
                            name={"arrow"}
                            color={color.white}
                            size={30}
                        />
                    </HeroCTAButton>
                    <HeroPhoneImage>
                        <StaticImage
                            src={"../assets/images/landing-hero-phone.png"}
                            alt={"Hero Phone Image"}
                            placeholder={"blurred"}
                            loading={"eager"}
                        />
                    </HeroPhoneImage>
                </HeroContentContainer>
                <Icon
                    onClick={() => document.getElementById("#info").scrollIntoView({ behavior: "smooth" })}
                    name={"faq"}
                    size={40}
                    color={color.black}
                />
            </HeroContainer>

            <MainContentWrapper id={"#info"}>
                <MainContentContainer>
                    <StoryParagraph>
                        <p>Imagine the best Burger 🍔 ever. <StoryPartLowkey>Or pizza 🍕 or fondue 🫕… whatever you fancy ✨.</StoryPartLowkey></p>
                    </StoryParagraph>
                    <StoryParagraph>
                        <p>Now imagine you're not aware that it exists right around the corner – no way to know.</p>
                    </StoryParagraph>
                    <StoryParagraph>
                        <p>After hours 🕥 of strolling you stumble upon a tiny run down shop with rustic decor 📺, plastic cutlery 🍴and boomer motivational quotes 📌 on the wall. <StoryPartEmphasis>The host doesn’t even understand your language 🧏.</StoryPartEmphasis></p>
                    </StoryParagraph>
                    <StoryParagraph>
                        <p>And there, buried in a messy word-formatted menu 📜, behind an unacceptably high price tag 💰 you’re only willing to pay because you feel like you have to at this point, is the burger 🍔 in question. <StoryPartLowkey>Or spinach quiche 🥬 or steak 🥩 – again, not the point.</StoryPartLowkey></p>
                    </StoryParagraph>
                    <StoryParagraph>
                        <p>Now imagine repeating this for every part of every meal 🍝, every time you go out. It is the only way you know at present.</p>
                    </StoryParagraph>
                    <StoryParagraph>
                        <p><StoryPartEmphasis>Well, that’s essentially the modern news experience in a nutshell. 🥜</StoryPartEmphasis></p>
                    </StoryParagraph>
                    <StoryParagraph>
                        <p>Some people might think all of that is „just how things are“… <StoryPartLowkey>or simply have a favorite place that has everything they want.</StoryPartLowkey></p>
                    </StoryParagraph>
                    <StoryParagraph>
                        <p><StoryPartUnderlined>We don’t.</StoryPartUnderlined> So we got to work. 🔧</p>
                    </StoryParagraph>
                </MainContentContainer>

                <MainContentContainer>
                    <LandingTimeline />
                </MainContentContainer>

                <MainContentContainer>
                    <LandingFeatures />
                </MainContentContainer>

                <MainContentContainer>
                    <BrandGrid />
                </MainContentContainer>
            </MainContentWrapper>

            <MainCardCTA callback={(event) => activateModal(event)} />

            <Footer />
        </main>
    )
}

export default IndexPage;

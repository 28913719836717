import styled from "styled-components";
import { color, font } from "../../design/theme";
import Icon from "../../assets/icons/icon.component";

export const LandingTimelineCardContainer = styled.div`
    border-radius: 30px;
    background-color: ${color.gray5};
    position: relative;
    width: 100%;
    height: max-content;
    padding: 30px 50px 30px 100px;
`

export const LandingTimelineCardPin = styled.div`
    height: 34px;
    width: 34px;
    padding: 7px 6px 0 6px;
    border-radius: 100px;
    box-shadow: 0 0 0 8px ${color.blue};
    background-color: ${color.gray5};
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 3;
`

export const LandingTimelineCardTitle = styled.div`
    font-family: ${font.pulpDisplayBold};
    font-size: 30px;
    color: ${color.black};
    margin-bottom: 20px;
`

export const LandingTimelineCardDesc = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 22px;
    color: ${color.black};
    text-align: left;
`

export const LandingTimelineCardStatus = styled(Icon)`
    top: 15px;
    right: 15px;
    position: absolute;
  
    ${
        props => props.current ? `
            @keyframes spin {
                to { transform: rotate(360deg) } ;
            }
            animation: spin 4s linear infinite;
        ` : null
    }
`

export const LandingTimelineCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const LandingTimelineCardExpo = styled.div`
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 10px;
`

export const LandingTimelineCardQuote = styled.div`
    width: 100%;
    padding: 0 10px 0 60px;
    height: auto;
    position: relative;
    font-family: ${font.interBoldItalic};
    font-size: 20px;
    white-space: pre-wrap;
    text-align: left;
    margin-top: 30px;
    line-height: 30px;
    font-style: italic;
`

export const LandingTimelineCardQuoteIcon = styled(Icon)`
    position: absolute;
    top: 0;
    left: 0;
`
import React from "react";

import { systemIcons } from "./system.icons";
import { locationIcons } from "./location.icons";
import { societyIcons } from "./topicIcons/society.icons";
import { economyIcons} from "./topicIcons/economy.icons";
import { sportsIcons } from "./topicIcons/sports.icons";
import { politicsIcons } from "./topicIcons/politics.icons";
import { technologyIcons } from "./topicIcons/technology.icons";
import { scienceIcons } from "./topicIcons/science.icons";
import { specialIcons } from "./special.icons";

import { capitalize } from "../../components/utils";

const IconMap = Object.assign(
    { },

    systemIcons,
    locationIcons,
    specialIcons,

    societyIcons,
    economyIcons,
    sportsIcons,
    politicsIcons,
    technologyIcons,
    scienceIcons
)

const Icon = (props) => {
    const { name, color = "white", size = 25, outline = false, alert = false, desc = "", hover = false, ...rest } = props;
    const startFinalName = hover ? name?.toString() + "Hover" : name?.toString();
    const semifinalName = outline ? startFinalName?.toString() + "Outline" : startFinalName?.toString();
    const finalName = alert ? semifinalName + "Alert" : semifinalName;
    const Shape = IconMap[finalName] ? IconMap[finalName] : IconMap[name] ? IconMap[name] : IconMap["unknown"];
    const label = desc ? desc : capitalize(finalName);

    return (
        <svg
            width={size}
            height={size}
            {...rest}
            viewBox={"0 0 25 25"}
            role={"img"}
            aria-label={`${label} Icon`}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="https://www.w3.org/1999/xlink"
            version="1.1"
            baseProfile="full"
        >
            <Shape color={color} />
        </svg>
    )
}

export default Icon;